import { PoolWithShares, Pool } from '@/services/pool/types';
import { rpcProviderService } from '@/services/rpc-provider/rpc-provider.service';
import BigNumberJs from 'bignumber.js';
import {
  spolarABI,
  sunriseABI,
  xpolarRewardPoolABI,
  ERC20ABI,
} from '@/composables/PolarisFinance/ABI';
import {
  BigNumberToString,
  sunriseNameToAddress,
  SPOLAR,
  getDisplayBalance,
} from '@/composables/PolarisFinance/utils';
import { Contract } from 'ethers';
import {
  computed,
  ComputedRef,
  InjectionKey,
  provide,
  reactive,
  Ref,
  toRefs,
} from 'vue';
import { accountToAddress, Network } from '@balancer-labs/sdk';
import usePoolQuery from '@/composables/queries/usePoolQuery';

export class AprProviderService {
  public aprs = {};
  private prices = [];
  private xpolarPoolQuery;
  private pools: PoolWithShares[] | Pool[] = [];
  private xpolarRewardPoolAddress =
    '0x140e8a21d08CbB530929b012581a7C7e696145eF';
  public aprsReceived?: (aprs: any) => void;

  constructor(pools: any, prices: any, xpolarPoolQuery) {
    this.xpolarPoolQuery = xpolarPoolQuery;
    this.pools = pools;
    this.prices = prices;
  }

  public init() {}
  async fetchAll() {
    /*if (this.pools.length == 0) {
      await new Promise((resolve, reject) => {
        const loop = () =>
        this.pools.length !== 0
            ? resolve(this.pools.length)
            : setTimeout(loop, 100);
        loop();
      });
    }*/
    const aprsPromises: any[] = [];
    for (var i = 0; i < this.pools.length; i++) {
      aprsPromises.push(this.fetch(this.pools[i]));
    }
    const aprs = await Promise.all(aprsPromises);
    for (var i = 0; i < this.pools.length; i++) {
      aprs[this.pools[i].address] = aprs[i];
    }
    if (!this.aprsReceived) return;
    this.aprsReceived(aprs);
  }

  private async fetch(pool: PoolWithShares | Pool) {
    const w3 = rpcProviderService.getJsonProvider(Network.AURORA);

    const xpolarRewardPool = new Contract(
      this.xpolarRewardPoolAddress,
      xpolarRewardPoolABI,
      w3
    );

    const poolAddress = pool.address;

    const PID = {
      '0x0993fa12d3256e85da64866354ec3532f187e178': 0,
      '0xf0b6cf745afe642c4565165922ad62d6a93857c1': 1,
      '0xd88a378abfe6b6e232525dfb03fbe01ecc863c10': 2,
      '0xa83f9fa9b51fc26e9925a07bc3375617b473e051': 3,
      '0xa215a58225b344cbb62fcf762e8e884dbedfbe58': 4,
      '0x293bbbef6087f681a8110f08bbdedadd13599fc3': 5,
      '0xceecce984f498ee00832670e9ca6d372f6ce155a': 6,
      '0x244caf21eaa7029db9d6b42ddf2d95800a2f5eb5': 7,
      '0x9cd44e44e8a61bc7dc34b04c762a3c0137a3707c': 8,
      '0x454adaa07eec2c432c0df4379a709b1fa4c800ed': 9,
      '0xb3a04902b78fbe61185b766866193630db4db8a3': 10,
      '0x4200333dc021ea5fb1050b8e4f8f3ed7cb1d22ed': 11,
      '0xd8e9e1916a4d98fb0dc6db725a8c8c2af08a329b': 12,
      '0x23a8a6e5d468e7acf4cc00bd575dbecf13bc7f78': 13,
      '0xf0f3b9eee32b1f490a4b8720cf6f005d4ae9ea86': 14,
      '0x3ac55ea8d2082fabda674270cd2367da96092889': 15,
      '0xafe0d6ca6aabb43cda024895d203120831ba0370': 16,
      '0xa69d9ba086d41425f35988613c156db9a88a1a96': 17,
      '0x17cbd9c274e90c537790c51b4015a65cd015497e': 18,
      '0x3a4773e600086a753862621a26a2e3274610da43': 19,
      '0x266437e6c7500a947012f19a3de96a3881a0449e': 20,
      '0x192bdcdd7b95a97ec66de5630a85967f6b79e695': 21,
      '0xce32b28c19c61b19823395730a0c7d91c671e54b': 22,
      '0xfa32616447c51f056db97bc1d0e2d4c0c4d059c9': 23,
      '0x89cc63050ade84bffafd7ec84d24fc0feb5f96c9': 24,
      '0xe370d4d0727d4e9b70db1a2f7d2efd1010ff1d6d': 25,
    };

    if (this.xpolarPoolQuery?.data === undefined) {
      await new Promise((resolve, reject) => {
        const loop = () =>
          this.xpolarPoolQuery?.data !== undefined
            ? resolve(this.xpolarPoolQuery?.data)
            : setTimeout(loop, 100);
        loop();
      });
    }

    if (
      this.prices['0xC42C30aC6Cc15faC9bD938618BcaA1a1FaE8501d'] === undefined
    ) {
      await new Promise((resolve, reject) => {
        const loop = () =>
          this.prices['0xC42C30aC6Cc15faC9bD938618BcaA1a1FaE8501d'] !==
          undefined
            ? resolve(this.prices['0xC42C30aC6Cc15faC9bD938618BcaA1a1FaE8501d'])
            : setTimeout(loop, 100);
        loop();
      });
    }

    if (pool.totalLiquidity == '0') {
      await new Promise((resolve, reject) => {
        const loop = () => {
          pool.totalLiquidity != '0'
            ? resolve(pool.totalLiquidity)
            : setTimeout(loop, 100);
          console.log('fetching pool total liquidity');
        };

        loop();
      });
    }
    const poolTotalLiquidty = pool.totalLiquidity;
    const xpolarPool = this.xpolarPoolQuery?.data;

    const xpolarBalance =
      xpolarPool?.onchain?.tokens['0xeaf7665969f1daa3726ceada7c40ab27b3245993']
        ?.balance;
    const nearBalance =
      xpolarPool?.onchain?.tokens['0x990e50e781004ea75e2ba3a67eb69c0b1cd6e3a6']
        ?.balance;

    const nearPrice =
      this.prices['0xC42C30aC6Cc15faC9bD938618BcaA1a1FaE8501d']['usd'];
    const xpolarPrice =
      (Number(nearBalance) / Number(xpolarBalance) / (0.2 / 0.4)) *
      Number(nearPrice);

    const pid = PID[poolAddress.toLowerCase()];

    const depositToken = new Contract(poolAddress, ERC20ABI, w3);

    const [xpolarPerSecond, allocPoint, stakedInPoolBigNumber] =
      await Promise.all([
        xpolarRewardPool.xpolarPerSecond(),
        xpolarRewardPool.poolInfo(pid),
        depositToken.balanceOf(this.xpolarRewardPoolAddress),
      ]);

    const stakedInPool = BigNumberToString(stakedInPoolBigNumber, 14, 4);

    const TVL = new BigNumberJs(poolTotalLiquidty || '')
      .div(pool.totalShares || '')
      .times(stakedInPool)
      .toString();

    const finalXpolarPerSecond = BigNumberToString(
      xpolarPerSecond.mul(allocPoint.allocPoint).div(800000),
      8,
      10
    );

    const tokenPerHour = Number(finalXpolarPerSecond) * 60 * 60;
    const totalRewardPricePerYear = tokenPerHour * 24 * 365 * xpolarPrice;
    const totalRewardPricePerDay = tokenPerHour * 24 * xpolarPrice;

    const dailyAPR = ((totalRewardPricePerDay / Number(TVL)) * 100)
      .toFixed(2)
      .toString();
    const yearlyAPR = ((totalRewardPricePerYear / Number(TVL)) * 100)
      .toFixed(2)
      .toString();

    return { dailyAPR: dailyAPR, yearlyAPR: yearlyAPR };
  }
}
